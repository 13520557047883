import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrAdminSoftwareView',
  computed: {
    ...mapState('company', ['companies'])
  },
  components: {
  }
})
export default class GtrAdminSoftwareView extends Vue {
  data () {
    return {
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'Company', align: 'start', sortable: true, value: 'name' },
          { text: 'Owner Name', value: 'owner.name' },
          { text: 'Owner Email', value: 'owner.email' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewCompanyForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('companies')
  onCompaniesChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  get microscannerLink () {
    return 'https://github.com/GTR-Events/microscanner-software-windows/blob/21323897937791d4ff348355de96fd124caf7cb8/OPN%20Soft/ScannerResetInstaller.exe?raw=true'
  }
}
